import { createStore } from 'vuex'

export default createStore({
    state: {
        sidebarCollapsed: false
      },
    mutations: {
    
    },
    actions: {
    
    },
    getters: {
    
    }
})