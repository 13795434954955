<template>
  <header class="bg-header-color text-white flex items-center justify-between min-h-20 px-2 rounded-lg relative">
    <!-- Sidebar Navigation -->
    <div class="flex-none inline-flex items-center px-3 max-[1023px]:hidden">
      <button class="text-3xl hover:text-pf-yellow" @click="$store.state.sidebarCollapsed = !$store.state.sidebarCollapsed">
        <CgMenuLeftAlt v-if="$store.state.sidebarCollapsed" />
        <CgMenuRightAlt v-if="!$store.state.sidebarCollapsed" />
      </button>
    </div>

    <!-- Logo -->
    <div class="flex-none inline-flex items-center px-3">
      <a href="/">
        <img src="../assets/logo-light.png" class="w-40" alt="" />
      </a>
    </div>

    <!-- User Info Details -->
    <div class="flex-1 inline-flex items-center px-3 max-[1023px]:hidden">
      <!-- Points -->
      <section class="px-5 max-[1279px]:px-2 inline-flex items-center">
        <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg" data-tooltip-target="tooltip-points" data-tooltip-placement="bottom">
          <MdRocket />
        </button>

        <div class="flex flex-col pl-2 max-[1279px]:hidden">
          <span class="text-[16px] font-bold text-white">332</span>
          <span class="text-[13px] text-black-100">Points</span>
        </div>
        
        <!-- tooltip -->
        <div id="tooltip-points" role="tooltip" class="min-[1280px]:hidden absolute z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
          <p class="text-[13px]"><b class="text-pf-yellow">332</b> Points</p>
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </section>

      <!-- Free Spins -->
      <section class="px-5 max-[1279px]:px-2 inline-flex items-center">
        <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg" data-tooltip-target="tooltip-freespins" data-tooltip-placement="bottom">
          <LuFerrisWheel />
        </button>

        <div class="flex flex-col pl-2 max-[1279px]:hidden">
          <span class="text-[16px] font-bold text-white">12</span>
          <span class="text-[13px] text-black-100">Free Spins</span>
        </div>

        <!-- tooltip -->
        <div id="tooltip-freespins" role="tooltip" class="min-[1280px]:hidden absolute z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
          <p class="text-[13px]"><b class="text-pf-yellow">12</b> Free Spins</p>
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </section>

      <!-- Free Tickets -->
      <section class="px-5 max-[1279px]:px-2 inline-flex items-center">
        <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg" data-tooltip-target="tooltip-tickets" data-tooltip-placement="bottom">
          <IoTicket />
        </button>

        <div class="flex flex-col pl-2 max-[1279px]:hidden">
          <span class="text-[16px] font-bold text-white">21</span>
          <span class="text-[13px] text-black-100">Free Tickets</span>
        </div>
        
        <!-- tooltip -->
        <div id="tooltip-tickets" role="tooltip" class="min-[1280px]:hidden absolute z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
          <p class="text-[13px]"><b class="text-pf-yellow">21</b> Free Tickets</p>
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </section>

      <!-- Level -->
      <section class="px-5 max-[1279px]:px-2 inline-flex items-center">
        <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg" data-tooltip-target="tooltip-level" data-tooltip-placement="bottom">
          <FlFilledCrown />
        </button>

        <div class="flex flex-col pl-2 max-[1279px]:hidden">
          <span class="text-[16px] font-bold text-white">17</span>
          <span class="text-[13px] text-black-100">Level</span>
        </div>
        
        <!-- tooltip -->
        <div id="tooltip-level" role="tooltip" class="min-[1280px]:hidden absolute z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
          <p class="text-[13px]"><b class="text-pf-yellow">17</b> Level</p>
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>        
      </section>
    </div>

    <!-- User Interaction Buttons -->
    <div class="flex-none inline-flex items-center px-3 max-[1023px]:hidden">
      <!-- Notifications Button -->
      <section class="inline-flex pr-4 items-center">
        <button class="bg-black-400 text-black-100 text-2xl px-3 py-3 rounded-lg relative">
          <BxSolidBellRing />
          <span class="absolute right-[-2.5px] top-[-2.5px] w-[10px] h-[10px] rounded-full bg-pf-yellow">&nbsp;</span>
        </button>
      </section>

      <!-- Account Button -->
      <section class="inline-flex pr-4 items-center">
        <button class="bg-black-400 text-black-100 text-2xl px-3 py-3 rounded-lg">
          <McUser3Fill />
        </button>
      </section>

      <!-- User Info -->
      <section class="inline-flex flex-col">
        <div class="text-white text-[14px] font-bold pb-2">👋 Alex</div>
        <div class="relative">
          <span class="bg-black-400 flex items-center w-[120px] h-[15px] px-1 rounded-lg cursor-pointer">
            <span class="bg-black-200 block w-[100%] h-[7.5px] rounded-lg" data-tooltip-target="tooltip-user-level-info" data-tooltip-placement="bottom">
              <span class="bg-pf-yellow block w-[72%] h-[100%] rounded-lg relative">
                <span
                  class="text-black-900 font-bold bg-pf-yellow w-[15px] h-[15px] text-[10px] text-center rounded-full absolute top-[-3px] right-[-10px]">7</span>
              </span>
            </span>
          </span>

          <div id="tooltip-user-level-info" role="tooltip" class="whitespace-nowrap absolute z-10 invisible inline-block px-3 py-2 transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
            <p class="text-[13px]">22 points to <b class="text-pf-green">Level 8</b></p>
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </section>
    </div>

    <!-- Mobile Navigation -->
    <div class="flex-none inline-flex items-center px-3 min-[1024px]:hidden" data-drawer-target="app-sidebar" data-drawer-toggle="app-sidebar" aria-controls="app-sidebar">
      <button class="text-3xl">
        <CgMenuRightAlt />
      </button>
    </div>
  </header>
  <SubHeader />
</template>

<script>
export default {
  name: "AppHeader",
  components: {
    SubHeader
  }
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import SubHeader from './SubHeader.vue';

// importing icons as SVG code
import { CgMenuLeftAlt } from '@kalimahapps/vue-icons';
import { CgMenuRightAlt } from '@kalimahapps/vue-icons';
import { MdRocket } from '@kalimahapps/vue-icons';
import { LuFerrisWheel } from '@kalimahapps/vue-icons';
import { IoTicket } from '@kalimahapps/vue-icons';
import { FlFilledCrown } from '@kalimahapps/vue-icons';
import { BxSolidBellRing } from '@kalimahapps/vue-icons';
import { McUser3Fill } from '@kalimahapps/vue-icons';

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
})

</script>

<style scoped>

</style>