<template>
  <main class="pf-container container mx-auto py-2 px-2 ">
    <navbar/>
    <div class="flex my-3 lg:space-x-10">
      <sidebar/>
      <router-view/>
    </div>
  </main>
</template>

<script>
import navbar from './components/Header.vue'
import sidebar from './components/Sidebar.vue';

export default {
  name: 'PanelFront',
  components: {
    sidebar,
    navbar
  }
}
</script>

<style>

</style>
