<template>
  <div class="flex h-[70px] mt-[-10px] mb-[-60px] lg:pl-[240px] px-3 items-center bg-black-600 rounded">

  </div>
</template>

<script>
export default {
  name: 'SubHeader'
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// importing icons as SVG code

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
})
</script>

<style scoped></style>